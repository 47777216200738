import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import getLocation from '../../../assets/countries.json';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// Redux
import { setEditUserView } from 'src/redux/slices/users.slice';

// Components
import AddUserForm from 'src/components/forms/admin/users/UserForm';
import ConfirmationPopup from 'src/ui/popups/ConfirmationPopup';

// Interfaces
import { userDetailGeneral, UsersDetailAllInfo } from 'src/interfaces/usersInterface';

// Services
import { getUserDetailUseCase, updateUserUseCase } from 'src/services/users/useCases';

const EditUser = () => {
  const params = useParams<{ userId: string }>();
  const userDetail: userDetailGeneral = useAppSelector((state) => state.users.userDetail);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [editInfo, setEditInfo] = useState({});
  const editUserView = useAppSelector((state) => state.users.editUserView);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['addEditUserForm']);
  const navigate = useNavigate();
  const location = getLocation.states;
  localStorage.setItem('current-user', JSON.stringify(params.userId));
  let stateEditing: any = '';

  const findState = (stateName: any) => {
    const state = location.find((state: any) => state.state_code === stateName);
    stateEditing = state;
    return state?.id;
  };

  const findCity = (cityName: any) => {
    if (stateEditing) {
      const city = stateEditing.cities.find((city: any) => city.name === cityName);
      return city?.id;
    }
  };

  const handleSuccess = async (values: UsersDetailAllInfo) => {
    const editLocation = {
      stateId: values.location?.stateId,
      cityId: values.location?.cityId,
    };
    const editValues: any = {};
    if (userDetail.fullName !== values.fullName) editValues.fullName = values.fullName;
    if (userDetail.email !== values.email) editValues.email = values.email;
    if (values.phone && userDetail.phone !== values.phone) editValues.phone = values.phone;
    if (userDetail.gender !== values.gender) editValues.gender = values.gender;
    if (values.location?.stateId) {
      if (findState(userDetail.state) !== Number(values.location?.stateId))
        editValues.location = editLocation;
      if (findCity(userDetail.city) !== Number(values.location?.cityId))
        editValues.location = editLocation;
    }
    if (`${moment(userDetail.birthDay).toDate()}` !== `${values.birthDay}`)
      editValues.birthDay = values.birthDay;
    if (userDetail.description !== values.description) editValues.description = values.description;
    editValues.preferredLocale = values.preferredLocale;

    const updateUserGeneralInfo = Object.assign({}, editValues);
    if (
      updateUserGeneralInfo.fullName ||
      updateUserGeneralInfo.email ||
      updateUserGeneralInfo.phone ||
      updateUserGeneralInfo.gender ||
      updateUserGeneralInfo.birthDay ||
      updateUserGeneralInfo.description ||
      updateUserGeneralInfo.location ||
      updateUserGeneralInfo.preferredLocale
    ) {
      setEditInfo(updateUserGeneralInfo);
      setShowEdit(true);
    } else {
      dispatch(setEditUserView('profile-picture'));
    }
  };
  const handleEdit = () => {
    dispatch(
      updateUserUseCase({
        publicId: params.userId,
        updatedUser: editInfo,
      }),
    );
  };

  useEffect(() => {
    if (editUserView === 'profile-picture') {
      navigate(`/admin/users/${params.userId}/edit-user/profile-picture`);
    }
  }, [editUserView, navigate, params.userId]);

  useEffect(() => {
    if (params.userId) {
      dispatch(getUserDetailUseCase(params.userId));
    }
  }, [dispatch, params.userId]);

  return (
    <>
      <AddUserForm onSuccess={handleSuccess} data={userDetail} isEditing={true} />
      <ConfirmationPopup
        isVisible={showEdit}
        title={t('editAlert.title')}
        subtitle={t('editAlert.subtitle')}
        submitBtn={t('editAlert.confirm')}
        handleSubmit={handleEdit}
        onClose={() => setShowEdit(false)}
      />
    </>
  );
};

export default EditUser;
