import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { useTranslation } from 'react-i18next';
import TablePagination from '@mui/material/TablePagination';
import useDebounce from 'src/hooks/useDebounce';

// Redux
import { setAddUserView, setEditUserView, setUserDetail } from 'src/redux/slices/users.slice';
import { setChangePasswordView } from 'src/redux/slices/auth.slice';

// Interfaces
import { Users, UsersTableProps } from 'src/interfaces/usersInterface';
import { UsersResponse } from 'src/redux/state.types';

// Components
import UserRow from '../../../rows/UserRow';

// Services
import { getUsersListUseCase } from 'src/services/users/useCases';
import AddUserOption from 'src/components/dropdowns/addUserOption';

const UsersTable = (props: UsersTableProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchInput, setSearchInput] = useState<string>('');
  const { color } = props;
  const { t } = useTranslation('users');

  const debouncedSearch = useDebounce(searchInput, 500); // 500ms delay

  const users: UsersResponse = useAppSelector((state) => state.users.users);
  const dispatch = useAppDispatch();
  const userTableHeaders = [
    { title: t('firstCol'), id: 1, space: '18%' },
    { title: t('seventhCol'), id: 7, space: '10%' },
    { title: t('secondCol'), id: 2, space: '18%' },
    { title: t('thirdCol'), id: 3, space: '13%' },
    { title: t('fourthCol'), id: 4, space: '10%' },
    { title: t('fifthCol'), id: 5, space: '14%' },
    { title: t('sixthCol'), id: 6, space: '17%' },
  ];
  useEffect(() => {
    dispatch(
      getUsersListUseCase({ Name: debouncedSearch, page: currentPage + 1, limit: rowsPerPage }),
    );
    dispatch(setUserDetail(''));
    dispatch(setEditUserView(''));
    dispatch(setAddUserView(''));
    dispatch(setChangePasswordView(''));
  }, [dispatch, currentPage, rowsPerPage, debouncedSearch]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
    <div
      className={`relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-2xl bg-white text-primary "
          ${color === 'light' ? 'bg-white' : 'bg-sky-900 text-white'}`}
    >
      <div className='rounded-t mb-0 px-4 py-3 border-0 flex justify-between'>
        <div className='flex flex-wrap items-center relative'>
          <AddUserOption
            header={t('header')}
            privateUser={t('privateUser')}
            businessUser={t('businessUser')}
          />
        </div>
        <div>
          <input
            className='border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-full text-sm shadow focus:ring-0 focus:outline-2 focus:border focus:border-secondary transition-all duration-150'
            type='text'
            name='Users'
            placeholder='Search the users'
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>
      </div>
      <div className='block w-full overflow-x-auto'>
        <div className='items-center bg-transparent border-collapse'>
          <div className='flex'>
            {userTableHeaders.map((header) => (
              <div
                key={header.id}
                style={{ width: header.space }}
                className={`usersTableHeader " +
                    ${
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-sky-800 text-sky-300 border-sky-700'
                    })`}
              >
                {header.title}
              </div>
            ))}
          </div>
          <div className='card-body'>
            {users.data.map((user: Users) => (
              <UserRow
                key={user.publicId}
                profilePicture={user.profilePicture}
                fullName={user.fullName}
                email={user.email}
                phone={user.phone}
                gender={user.gender}
                publicId={user.publicId}
                status={user.status}
                lastActivity={user.lastActivity}
                userType={user.userType}
              />
            ))}
          </div>
          <div className='him-pagination'>
            {/* <Icon
              icon='left'
              extraClass={`mr-4 ${
                users.metadata?.totalCount === 0 || currentPage === 1 ? '' : null
              }`}
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={users.metadata?.totalCount === 0 || currentPage === 1}
            />
            <Icon
              icon='right'
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={
                users.metadata?.totalCount === 0 || currentPage === users.metadata?.pageCount
              }
            /> */}
            <TablePagination
              component='div'
              count={users.metadata?.totalCount || 0}
              page={currentPage}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersTable;
