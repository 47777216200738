import UserBussinessForm from 'src/components/forms/admin/users/UserBussinessForm';
import { UsersDetailAllInfo } from 'src/interfaces/usersInterface';
import { useAppDispatch } from 'src/hooks';
import { createUserUseCase } from 'src/services/users/useCases';
import { UserGeneralInfo } from 'src/interfaces/usersInterface';

const AddBussinessUser = () => {
  const dispatch = useAppDispatch();

  const handleSuccess = async (values: UsersDetailAllInfo) => {
    const postUserGeneralInfo: Partial<UsersDetailAllInfo> = { ...values };

    // Define fields to remove
    const fieldsToRemove: (keyof UsersDetailAllInfo)[] = [
      'isEditing',
      'photos',
      'profilePicture',
      'lastName',
    ];

    // Remove specified fields
    fieldsToRemove.forEach((field) => {
      if (field in postUserGeneralInfo) {
        delete postUserGeneralInfo[field];
      }
    });

    // Remove optional fields if they are empty or undefined
    if (!values.location?.cityId && !values.location?.stateId) {
      delete postUserGeneralInfo.location;
    }
    if (!values.description) {
      delete postUserGeneralInfo.description;
    }
    if (!values.phone) {
      delete postUserGeneralInfo.phone;
    }
    if (!values.gender) {
      delete postUserGeneralInfo.gender;
    }
    if (!values.birthDay) {
      delete postUserGeneralInfo.birthDay;
    }
    // Ensure all required fields are present and of the correct type

    const userGeneralInfo: UserGeneralInfo = {
      fullName: postUserGeneralInfo.fullName ?? '',
      email: postUserGeneralInfo.email ?? '',
      password: postUserGeneralInfo.password ?? '',
      gender: postUserGeneralInfo.gender,
      preferredLocale: postUserGeneralInfo.preferredLocale ?? '',
      userType: postUserGeneralInfo.userType ?? '',
      businessName: postUserGeneralInfo.businessName
        ? postUserGeneralInfo.businessName.value // Ensure this matches BussinessUserInfo structure
        : null,
      spocName: postUserGeneralInfo.spocName ? postUserGeneralInfo?.spocName.value : null,
      spocEmail: postUserGeneralInfo.spocEmail ? postUserGeneralInfo.spocEmail.value : null,
      buccPopup: postUserGeneralInfo.buccPopup ?? false,
      rateId: postUserGeneralInfo.rateId?.value || 0,
      startDate: postUserGeneralInfo.startDate || null,
      endDate: postUserGeneralInfo.endDate || null,
      noOfDays: postUserGeneralInfo.rateId?.noofDays || 0,
      couponCycles: postUserGeneralInfo?.numberOfCycles || 0,
      monthEndDate: postUserGeneralInfo?.monthEndDate || null,
      sessionsCount: postUserGeneralInfo?.sessionsCount || 0,
      noOfMonths: postUserGeneralInfo?.noOfMonths || 0,
      birthDay: postUserGeneralInfo.birthDay || null,
    };

    dispatch(createUserUseCase(userGeneralInfo));
  };

  return (
    <div>
      <UserBussinessForm onSuccess={handleSuccess} isEditing={false} />
    </div>
  );
};

export default AddBussinessUser;
