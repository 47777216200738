import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Input from '../../../../ui/Input';
import Icon from '../../../../ui/Icon';
import DatePicker from 'react-datepicker';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import {
  bussinessUserFormFormikProps,
  BussinessUserFormProps,
  RateIdOption,
} from '../../../../interfaces/ui/formInterface';
import Button from 'src/ui/Button';
import { useEffect, useState } from 'react';
import PopUp from 'src/ui/Popup';
import { BussinessResponse, SubscriptionListResponse } from 'src/redux/state.types';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
  getBussinessNameListUseCase,
  getSpocUserListUseCase,
  getBUSubscriptonListUseCase,
} from 'src/services/users/useCases';
import ReactTooltip from 'react-tooltip';

const UserBussinessForm = (props: BussinessUserFormProps) => {
  const { data, isEditing, onSuccess } = props;
  const { t } = useTranslation(['addEditUserForm']);

  const [userAddedPopUp, setUserAddedPopUp] = useState(false);
  const [businessesData, setBusinessesData] = useState<any[]>([]);
  const [spocNameData, setSpocNameData] = useState<any[]>([]);
  const [spocEmailData, setSpocEmailData] = useState<any[]>([]);
  const [rateIdOptions, setRateIdOptions] = useState<any>([]);
  const [searchKey, setSearchKey] = useState<any>(null);

  const BussinessUser: BussinessResponse = useAppSelector((state) => state.users.bussinessUser);
  const BuSubscriptionList: SubscriptionListResponse = useAppSelector(
    (state) => state.users.buSubscriptionList,
  );
  const addUserView = useAppSelector((state) => state.users.addUserView);
  const businessName = useAppSelector((state) => state.users.bussinessName);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const genders = [
    { name: t('genderTwo'), id: 1, value: t('genderTwo') },
    { name: t('genderOne'), id: 2, value: t('genderOne') },
    { name: t('genderThree'), id: 3, value: t('genderThreeUsable') },
  ];
  useEffect(() => {
    dispatch(getBUSubscriptonListUseCase({ page: 1, limit: 10 }));
    dispatch(getBussinessNameListUseCase());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSpocUserListUseCase(searchKey));
  }, [dispatch, searchKey]);

  useEffect(() => {
    if (addUserView) {
      setUserAddedPopUp(true);
    }
  }, [addUserView]);

  // Function to find a business object by businessName
  const findBusinessByName = (businessName: any, arr: any) => {
    return arr.find((business: any) => business.label === businessName) || null;
  };

  useEffect(() => {
    const businessArray: any = [];
    const spocNameArray: any = [];
    const spocEmailArray: any = [];

    businessName.data.forEach((item: any) => {
      businessArray.push({
        label: item.businessName,
        value: item.businessName,
      });
    });

    BussinessUser.data.forEach((item: any) => {
      // Push spocName if it doesn't exist in the array already
      if (!spocNameArray.some((e: any) => e.spocName === item.spocName)) {
        spocNameArray.push({
          label: item.spocName,
          value: item.spocName,
          spocEmail: item.spocEmail,
        });
      }

      // Push spocEmail if it doesn't exist in the array already
      if (!spocEmailArray.some((e: any) => e.spocEmail === item.spocEmail)) {
        spocEmailArray.push({
          label: item.spocEmail,
          value: item.spocEmail,
        });
      }
    });

    setBusinessesData(businessArray);
    setSpocEmailData(spocEmailArray);
    setSpocNameData(spocNameArray);
  }, [BussinessUser, businessName]);

  const formik = useFormik<bussinessUserFormFormikProps>({
    initialValues: {
      fullName: data?.fullName || '',
      email: data?.email || '',
      password: isEditing ? 'Password@123' : '',
      gender: data?.gender || '',
      businessName: isEditing
        ? findBusinessByName(data?.businessName, businessesData) || null
        : data?.businessName || null,
      rateId: data?.rateId || null,
      spocName: isEditing
        ? findBusinessByName(data?.spocName, spocNameData) || null
        : data?.spocName || null,
      spocEmail: isEditing
        ? findBusinessByName(data?.spocEmail, spocEmailData) || null
        : data?.spocEmail || null,
      numberOfCycles: data?.numberOfCycles || 0,
      startDate: data?.startDate || null,
      endDate: data?.endDate || null,
      userType: 'Business',
      preferredLocale: 'en-US',
      buccPopup: true,
      monthEndDate: null,
      sessionsCount: data?.sessionsCount || 0,
      noOfMonths: data?.noOfMonths || 0,
      birthDay: null,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      fullName: Yup.string()
        .required(t('errorRequiredOne'))
        .min(3, t('errorCharactersOne'))
        .trim()
        .matches(/^[A-Za-z]*(\s[A-Za-z]*)+$/, t('errorCharactersTwo')),
      email: Yup.string().email(t('errorValidTwo')).required(t('errorRequiredTwo')),
      password: Yup.string()
        .required(t('errorRequiredThree'))
        .min(8, t('errorPasswordOne'))
        .matches(/[a-z]+/, t('errorPasswordTwo'))
        .matches(/[A-Z]+/, t('errorPasswordThree'))
        .matches(/\d+/, t('errorPasswordFour')),
      gender: Yup.string().required('Select gender.'),
      businessName: Yup.object()
        .shape({
          value: Yup.string(),
          label: Yup.string(),
          publicId: Yup.string(),
        })
        .nullable()
        .required('Select Business Name.'),
      rateId: Yup.object()
        .shape({
          value: Yup.number(),
          label: Yup.string(),
          cycles: Yup.number(),
        })
        .nullable()
        .required('Select Rate ID.'),
      numberOfCycles: Yup.number().when('rateId', {
        is: (rateId: any) => isCustomPlanSelected(rateIdOptions, rateId?.value),
        then: (schema) =>
          schema
            .required('Select Number of months.')
            .nullable()
            .when('sessionsCount', {
              is: (sessionsCount: number | null) => sessionsCount !== null && sessionsCount > 1,
              then: (schema) =>
                schema.min(
                  1,
                  'Number of months cannot be selected when sessions count is more than 1.',
                ),
            }),
        otherwise: (schema) =>
          schema
            .required('Select Number of Cycles.')
            .nullable()
            .min(1, 'Number of cycles must be greater than 0.')
            .when('rateId', (rateId, schema) => {
              return schema.max(
                rateId?.[0]?.cycles || Infinity,
                'Number of cycles cannot exceed the maximum for the selected rate.',
              );
            }),
      }),
      startDate: Yup.date().nullable().required('Start date is required.'),
      sessionsCount: Yup.number().when('rateId', {
        is: (rateId: any) => isCustomPlanSelected(rateIdOptions, rateId?.value),
        then: (schema) =>
          schema
            .required('Select Number of sessions.')
            .nullable()
            .min(0, 'Number of sessions must be greater than 0.')
            .max(100, 'Number of sessions must be lesser than 100.'),
        otherwise: (schema) => schema.nullable(),
      }),
      noOfMonths: Yup.number().when('rateId', {
        is: (rateId: any) => isCustomPlanSelected(rateIdOptions, rateId?.value),
        then: (schema) =>
          schema
            .required('Select Number of sessions month.')
            .nullable()
            .min(0, 'Number of months must be greater than 0.'),
        otherwise: (schema) => schema.nullable(),
      }),
      birthDay: Yup.date().required('Select Date of Birth.').nullable(),
    }),
    onSubmit: (values) => {
      onSuccess(values);
    },
  });

  useEffect(() => {
    if (
      formik.values.startDate &&
      formik.values.numberOfCycles > 0 &&
      formik.values.rateId?.noofDays
    ) {
      const endDate = new Date(formik.values.startDate);
      const totalTime = formik.values.numberOfCycles * formik.values.rateId?.noofDays;
      endDate.setDate(endDate.getDate() + totalTime); // Add 30 days
      formik.setFieldValue('endDate', endDate);
    } else {
      formik.setFieldValue('endDate', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.startDate, formik.values.numberOfCycles, formik.values.rateId]);

  useEffect(() => {
    if (formik.values.startDate && formik.values.noOfMonths > 0 && formik.values.rateId?.noofDays) {
      const endDate = new Date(formik.values.startDate);
      const totalTime = formik.values.noOfMonths * formik.values.rateId?.noofDays;
      endDate.setDate(endDate.getDate() + totalTime); // Add 30 days
      formik.setFieldValue('monthEndDate', endDate);
    } else {
      formik.setFieldValue('monthEndDate', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.startDate, formik.values.noOfMonths, formik.values.rateId]);

  const handleRateIdChange = (selectedOption: RateIdOption | null) => {
    formik.setFieldValue('rateId', selectedOption);
    if (selectedOption) {
      formik.setFieldValue(
        'numberOfCycles',
        Math.min(formik.values.numberOfCycles || 0, selectedOption.noofCycles),
      );
    }
  };

  useEffect(() => {
    const arr: any = [];
    if (BuSubscriptionList) {
      BuSubscriptionList.data.forEach((element: any) => {
        arr.push({
          label: element.subscrName,
          value: element.buSubscrId,
          noofCycles: element.noofCycles,
          noofDays: element.noofDays,
          freeRCSessCount: element.freeRCSessCount,
        });
      });
    }
    setRateIdOptions(arr);
  }, [BuSubscriptionList]);

  useEffect(() => {
    if (formik.values.sessionsCount === 0 || formik.values.sessionsCount === null) {
      formik.setFieldValue('noOfMonths', 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.sessionsCount]);

  const onSpocNameHandler = (selectedOption: any) => {
    formik.setFieldValue('spocName', selectedOption);
    if (selectedOption && selectedOption.spocEmail) {
      const spocEmailObject = {
        label: selectedOption.spocEmail,
        value: selectedOption.spocEmail,
      };
      formik.setFieldValue('spocEmail', spocEmailObject);
    } else {
      formik.setFieldValue('spocEmail', null);
    }
  };

  const isCustomPlanSelected = (rateIdOptions: any, compareId: any): boolean => {
    const customPlanOption = rateIdOptions.find((option: any) => option.label === 'Custom Plan');
    if (!customPlanOption || !compareId) {
      return false;
    }
    return customPlanOption.value === compareId;
  };

  const subtractYears = (numOfYears: number, date = new Date()) => {
    date.setFullYear(date.getFullYear() - numOfYears);
    return date;
  };

  return (
    <div className='relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-2xl bg-white text-primary overflow-auto'>
      <div className='flex items-center p-3 bg-background'>
        <Icon icon='backBtn' onClick={() => navigate(-1)} />
        <span className='flex font-semibold text-sm mx-2 uppercase'>
          {data ? t('headerEditBussiness') : t('headerAddBussiness')}
        </span>
      </div>
      <div className='formContainer'>
        <form onSubmit={formik.handleSubmit} className='bg-white shadow-md rounded px-8 pt-6 pb-4'>
          <div className='formUsersContainer'>
            <div className='mr-8 flex-1'>
              <div className='formUsersHeader'>{t('requiredOne')}</div>
              {formik.touched.fullName && formik.errors.fullName && (
                <div className='formUsersError'>{formik.errors.fullName}</div>
              )}
              <Input
                extraClass='w-full'
                type='text'
                name='fullName'
                value={formik.values.fullName}
                placeholder='John'
                onBlur={formik.handleBlur}
                onChange={(e) => formik.handleChange(e)}
                haveError={formik.touched.fullName && formik.errors.fullName}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                  !/^[A-Za-z\s]*$/.test(e.key) && e.preventDefault()
                }
                disabled={isEditing}
                required={true}
              />
            </div>
            <div className='mr-8 flex-1'>
              <div className='formUsersHeader'>{t('requiredBussinessTwo')}</div>
              {formik.touched.email && formik.errors.email && (
                <div className='formUsersError'>{formik.errors.email}</div>
              )}
              <Input
                extraClass='w-full'
                type='text'
                name='email'
                value={formik.values.email}
                placeholder='john.doe@example.com'
                onBlur={formik.handleBlur}
                onChange={(e) => formik.handleChange(e)}
                haveError={formik.touched.email && formik.errors.email}
                disabled={isEditing}
                required={true}
              />
            </div>
            <div className='flex-1'>
              <div className='formUsersHeader'>{t('requiredThree')}</div>
              {formik.touched.password && formik.errors.password && (
                <div className='formUsersError'>{formik.errors.password}</div>
              )}
              <Input
                extraClass='w-full'
                type='password'
                name='password'
                value={formik.values.password}
                placeholder={t('requiredThree')}
                onBlur={formik.handleBlur}
                onChange={(e) => formik.handleChange(e)}
                haveError={formik.touched.password && formik.errors.password}
                disabled={isEditing}
                required={true}
              />
            </div>
          </div>
          <div className='formUsersContainer mt-8'>
            <div className='mr-8 flex-1'>
              <div className='formUsersHeader'>{t('optionalFive')}</div>
              {formik.touched.gender && formik.errors.gender && (
                <div className='formUsersError'>{formik.errors.gender}</div>
              )}
              <select
                className={` ${
                  formik.touched.gender && formik.errors.gender
                    ? 'w-full formUsersInput error1'
                    : 'w-full formUsersInput'
                }`}
                name='gender'
                onChange={(e) => formik.handleChange(e)}
                value={formik.values.gender}
                onBlur={formik.handleBlur}
                disabled={isEditing}
                required={true}
              >
                <option value='' disabled hidden>
                  {t('selectOne')}
                </option>
                <option value=''>{t('selectOne')}</option>
                {genders.map((gender) => (
                  <option value={gender.value} key={gender.id}>
                    {gender.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='mr-8 flex-1'>
              <div className='formUsersHeader'>{t('requiredFour')}</div>
              {formik.touched.businessName && formik.errors.businessName && (
                <div className='formUsersError'>{formik.errors.businessName}</div>
              )}

              <CreatableSelect
                className={`${
                  formik.touched.businessName && formik.errors.businessName
                    ? 'formUsersdropdown error1'
                    : 'formUsersdropdown'
                }`}
                isClearable
                placeholder='Enter a business name'
                name='businessName'
                value={formik.values.businessName}
                options={businessesData}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
                maxMenuHeight={170}
                styles={styles}
                onChange={(selectedOption: any) => {
                  if (selectedOption) {
                    setSearchKey(selectedOption.value);
                    formik.setFieldValue('businessName', selectedOption);
                  } else {
                    formik.setFieldValue('businessName', null);
                  }
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: 'rgb(147 98 199)',
                  },
                })}
                isDisabled={isEditing}
                required={true}
              />
            </div>
            <div className='flex-1'>
              <div className='formUsersHeader'>{t('requiredFive')}</div>
              {formik.touched.rateId && formik.errors.rateId && (
                <div className='formUsersError'>{formik.errors.rateId}</div>
              )}
              <Select
                className={`${
                  formik.touched.rateId && formik.errors.rateId
                    ? 'formUsersdropdown error1'
                    : 'formUsersdropdown'
                }`}
                isClearable
                name='rateId'
                placeholder='Enter rate ID'
                value={formik.values.rateId}
                options={rateIdOptions}
                onChange={handleRateIdChange}
                onBlur={formik.handleBlur}
                required={true}
                styles={styles}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: 'rgb(147 98 199)',
                  },
                })}
                // isDisabled={isEditing}
              ></Select>
            </div>
          </div>
          <div className='formUsersContainer mt-8'>
            <div className='mr-8 flex-1'>
              <div className='formUsersHeader'>{t('requiredSix')}</div>
              {formik.touched.spocName && formik.errors.spocName && (
                <div className='formUsersError'>{formik.errors.spocName}</div>
              )}
              <CreatableSelect
                className={`${
                  formik.touched.spocName && formik.errors.spocName
                    ? 'formUsersdropdown error1'
                    : 'formUsersdropdown'
                }`}
                isClearable
                name='spocName'
                value={formik.values.spocName}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
                placeholder='Enter a spoc name'
                options={spocNameData}
                styles={styles}
                maxMenuHeight={170}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: 'rgb(147 98 199)',
                  },
                })}
                onChange={(selectedOption) => {
                  onSpocNameHandler(selectedOption);
                }}
                isDisabled={isEditing}
              />
            </div>
            <div className='mr-8 flex-1'>
              <div className='formUsersHeader'>{t('requiredSeven')}</div>
              {formik.touched.spocEmail && formik.errors.spocEmail && (
                <div className='formUsersError'>{formik.errors.spocEmail}</div>
              )}
              <CreatableSelect
                className={`${
                  formik.touched.spocEmail && formik.errors.spocEmail
                    ? 'formUsersdropdown error1'
                    : 'formUsersdropdown'
                }`}
                isClearable
                placeholder='Enter a spoc email address'
                value={formik.values.spocEmail}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.value}
                name='spocEmail'
                options={spocEmailData} // Assuming 'email' is the property you want
                maxMenuHeight={170}
                styles={styles}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    formik.setFieldValue('spocEmail', selectedOption);
                  } else {
                    formik.setFieldValue('spocEmail', null);
                  }
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: 'rgb(147 98 199)',
                  },
                })}
                isDisabled={isEditing}
              />
            </div>
            {!isCustomPlanSelected(rateIdOptions, formik.values.rateId?.value) && (
              <div className='flex-1'>
                <div className='formUsersHeader'>{t('requiredEight')}</div>
                {formik.touched.numberOfCycles && formik.errors.numberOfCycles && (
                  <div className='formUsersError'>{formik.errors.numberOfCycles}</div>
                )}
                <Input
                  extraClass='w-full'
                  type='number'
                  name='numberOfCycles'
                  value={formik.values.numberOfCycles?.toString() || ''}
                  placeholder='Enter a number of cycles'
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    const value = e.target.value ? parseInt(e.target.value, 10) : null;
                    const maxCycles = formik.values.rateId?.noofCycles || Infinity;
                    formik.setFieldValue(
                      'numberOfCycles',
                      value !== null ? Math.min(value, maxCycles) : null,
                    );
                  }}
                  required={true}
                  max={formik.values.rateId?.noofCycles}
                  min={0}
                  haveError={formik.touched.numberOfCycles && formik.errors.numberOfCycles}
                  disabled={isEditing}
                />
              </div>
            )}
            {isCustomPlanSelected(rateIdOptions, formik.values.rateId?.value) && (
              <div className='flex-1'>
                <div className='formUsersHeader'>{t('requiredEleven')}</div>
                {formik.touched.sessionsCount && formik.errors.sessionsCount && (
                  <div className='formUsersError'>{formik.errors.sessionsCount}</div>
                )}
                <Input
                  extraClass='w-full'
                  type='number'
                  name='sessionsCount'
                  value={formik.values.sessionsCount?.toString() || ''}
                  placeholder='Enter a number of sessions'
                  onChange={(e) => {
                    const value = e.target.value ? parseInt(e.target.value, 10) : null;
                    if (value != null && value > 0) {
                      formik.setFieldValue('sessionsCount', value);
                    } else {
                      formik.setFieldValue('sessionsCount', value);
                      formik.setFieldValue('numberOfCycles', 0);
                    }
                  }}
                  required={true}
                  max={100}
                  min={0}
                  haveError={formik.touched.sessionsCount && formik.errors.sessionsCount}
                  disabled={isEditing}
                />
              </div>
            )}
          </div>
          {isCustomPlanSelected(rateIdOptions, formik.values.rateId?.value) && (
            <div className='formUsersContainer mt-8'>
              <div className='mr-8 flex-1'>
                <div className='formUsersHeader'>{t('requiredTwelve')}</div>
                {formik.touched.numberOfCycles && formik.errors.numberOfCycles && (
                  <div className='formUsersError'>{formik.errors.numberOfCycles}</div>
                )}
                <Input
                  extraClass='w-full'
                  type='number'
                  name='numberOfCycles'
                  value={formik.values.numberOfCycles?.toString() || ''}
                  placeholder='Enter a number of cycles'
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    const value = e.target.value ? parseInt(e.target.value, 10) : null;
                    const maxCycles = formik.values.rateId?.noofCycles || Infinity;
                    formik.setFieldValue(
                      'numberOfCycles',
                      value !== null ? Math.min(value, maxCycles) : null,
                    );
                  }}
                  required={true}
                  max={12}
                  min={0}
                  haveError={formik.touched.numberOfCycles && formik.errors.numberOfCycles}
                  disabled={
                    isEditing ||
                    formik.values.sessionsCount === 0 ||
                    formik.values.sessionsCount === null
                  }
                />
              </div>
              <div className=' mr-8 flex-1'>
                <div className='formUsersHeader'>{t('requiredThirteen')}</div>
                {formik.touched.noOfMonths && formik.errors.noOfMonths && (
                  <div className='formUsersError'>{formik.errors.noOfMonths}</div>
                )}
                <Input
                  extraClass='w-full'
                  type='number'
                  name='noOfMonths'
                  value={formik.values.noOfMonths?.toString() || ''}
                  placeholder='Enter a number of months'
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    const value = e.target.value ? parseInt(e.target.value, 10) : null;
                    formik.setFieldValue('noOfMonths', value);
                  }}
                  required={true}
                  max={formik.values.rateId?.noofCycles}
                  haveError={formik.touched.noOfMonths && formik.errors.noOfMonths}
                  disabled={isEditing}
                />
              </div>
              <div className='flex-1'>
                <div className='formUsersHeader'>{t('requiredNine')}</div>
                {formik.touched.startDate && formik.errors.startDate && (
                  <div className='formUsersError'>{formik.errors.startDate}</div>
                )}
                <DatePicker
                  placeholderText='MM/DD/YYYY'
                  selected={formik.values.startDate}
                  name='startDate'
                  onChange={(date: Date | null) => formik.setFieldValue('startDate', date)}
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-full text-sm shadow focus:ring-0 focus:outline-2 focus:border focus:border-secondary w-full ease-linear transition-all duration-150
                ${formik.touched.startDate && formik.errors.startDate ? 'error' : ''}`}
                  dateFormat='MM/dd/yyyy h:mm aa'
                  dateFormatCalendar='MMMM'
                  showYearDropdown
                  isClearable
                  scrollableYearDropdown
                  minDate={new Date()}
                  required
                />
              </div>
            </div>
          )}

          {!isCustomPlanSelected(rateIdOptions, formik.values.rateId?.value) && (
            <div className='formUsersContainer flex mt-8'>
              <div className='mr-8 flex-1'>
                <div className='formUsersHeader'>{t('requiredNine')}</div>
                {formik.touched.startDate && formik.errors.startDate && (
                  <div className='formUsersError'>{formik.errors.startDate}</div>
                )}
                <DatePicker
                  placeholderText='MM/DD/YYYY'
                  selected={formik.values.startDate}
                  name='startDate'
                  onChange={(date: Date | null) => formik.setFieldValue('startDate', date)}
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-full text-sm shadow focus:ring-0 focus:outline-2 focus:border focus:border-secondary w-full ease-linear transition-all duration-150
                ${formik.touched.startDate && formik.errors.startDate ? 'error' : ''}`}
                  dateFormat='MM/dd/yyyy h:mm aa'
                  dateFormatCalendar='MMMM'
                  showYearDropdown
                  isClearable
                  scrollableYearDropdown
                  minDate={new Date()}
                  required
                />
              </div>
              <div className='mr-8 flex-1'>
                <div className='formUsersHeader'>{t('requiredTen')}</div>
                {formik.touched.endDate && formik.errors.endDate && (
                  <div className='formUsersError'>{formik.errors.endDate}</div>
                )}
                <DatePicker
                  placeholderText='MM/DD/YYYY'
                  selected={formik.values.endDate}
                  name='endDate'
                  onChange={(date: Date | null) => formik.setFieldValue('endDate', date)}
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-full text-sm shadow focus:ring-0 focus:outline-2 focus:border focus:border-secondary w-full ease-linear transition-all duration-150
                  ${formik.touched.endDate && formik.errors.endDate ? 'error' : ''}`}
                  dateFormat='MM/dd/yyyy h:mm aa'
                  dateFormatCalendar='MMMM'
                  showYearDropdown
                  scrollableYearDropdown
                  minDate={new Date()}
                  disabled={true}
                />
              </div>
              <div className='flex-1'>
                <div className='text-primary text-sm flex items-center'>
                  <span className='formUsersHeader mb-0'>{t('optionalSix')}</span>
                  <ReactTooltip
                    id='info-icon'
                    place='top'
                    className='w-56 bg-secondary text-primary rounded-full !important'
                    backgroundColor='bg-secondary'
                    arrowColor='#9362C7'
                    textColor='white'
                  />
                  <i data-for='info-icon' data-tip={t('over18')}>
                    <Icon icon='info' />
                  </i>
                </div>
                {formik.touched.birthDay && formik.errors.birthDay && (
                  <div className='formUsersError'>{formik.errors.birthDay}</div>
                )}
                <DatePicker
                  placeholderText='MM/DD/YYYY'
                  selected={formik.values.birthDay}
                  name='birthDay'
                  onChange={(date: Date | null) => formik.setFieldValue('birthDay', date)}
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-full text-sm shadow focus:ring-0 focus:outline-2 focus:border focus:border-secondary w-full ease-linear transition-all duration-150
              ${formik.touched.birthDay && formik.errors.birthDay ? 'border-alert' : ''}`}
                  dateFormat='MM/dd/yyyy'
                  maxDate={subtractYears(18)}
                  dateFormatCalendar='MMMM'
                  showYearDropdown
                  isClearable
                  scrollableYearDropdown
                  yearDropdownItemNumber={50}
                />
              </div>
            </div>
          )}
          {isCustomPlanSelected(rateIdOptions, formik.values.rateId?.value) && (
            <div className='formUsersContainer flex mt-8'>
              <div className='flex-1'>
                <div className='text-primary text-sm flex items-center'>
                  <span className='formUsersHeader mb-0'>{t('optionalSix')}</span>
                  <ReactTooltip
                    id='info-icon'
                    place='top'
                    className='w-56 bg-secondary text-primary rounded-full !important'
                    backgroundColor='bg-secondary'
                    arrowColor='#9362C7'
                    textColor='white'
                  />
                  <i data-for='info-icon' data-tip={t('over18')}>
                    <Icon icon='info' />
                  </i>
                </div>
                {formik.touched.birthDay && formik.errors.birthDay && (
                  <div className='formUsersError'>{formik.errors.birthDay}</div>
                )}
                <DatePicker
                  placeholderText='MM/DD/YYYY'
                  selected={formik.values.birthDay}
                  name='birthDay'
                  onChange={(date: Date | null) => formik.setFieldValue('birthDay', date)}
                  className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-full text-sm shadow focus:ring-0 focus:outline-2 focus:border focus:border-secondary w-full ease-linear transition-all duration-150
              ${formik.touched.birthDay && formik.errors.birthDay ? 'border-alert' : ''}`}
                  dateFormat='MM/dd/yyyy'
                  maxDate={subtractYears(18)}
                  dateFormatCalendar='MMMM'
                  showYearDropdown
                  isClearable
                  scrollableYearDropdown
                  yearDropdownItemNumber={50}
                />
              </div>
              <div className='flex-1'></div>
              <div className='flex-1'></div>
            </div>
          )}
          <div className='flex justify-center'>
            <Button type='submit' extraClass='w-2/12 p-2 mt-4'>
              {`${data ? t('nextBtn') : t('submitBtn')}`}
            </Button>
          </div>
        </form>
      </div>
      <PopUp
        isVisible={userAddedPopUp}
        extraClassesChildren='flex flex-col items-center justify-center'
        onClose={() => {
          setUserAddedPopUp(false);
          navigate('/admin/users');
        }}
      >
        <div className='flex flex-col items-center justify-content '>
          <span className='font-bold text-4xl text-purple-500'>USER ADDED</span>
          <button
            className='font-bold text-sm text-white bg-purple-600 w-48 h-12 mt-8'
            style={{
              borderRadius: '20px',
            }}
            onClick={() => {
              setUserAddedPopUp(false), navigate('/admin/users');
            }}
          >
            OKAY
          </button>
        </div>
      </PopUp>
    </div>
  );
};

export default UserBussinessForm;

const styles = {
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    minHeight: 'unset',
    height: '44px !important',
    borderWidth: state.isFocused ? 1 : 0,
    borderRadius: '30px',
    boxShadow: state.isFocused ? 'none' : 'none',
  }),
};
